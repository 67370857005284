.pendingVerification,
.verifiedApproved,
.notVerified,
.verifiedDenied {
  display: flex;
  position: relative;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 700;
  height: 20px;
  justify-content: center;
  top: 50%;
  width: 20px;
}

.pendingVerification,
.verifiedApproved,
.verifiedDenied {
  border: 1.5px solid #6366f1;
  color: #6366f1;
}

.pendingVerification::before,
.notVerified::before,
.verifiedDenied::before {
  content: "";
  position: absolute;
  height: 13px;
  transform: translateX(-50%);
  width: 1.5px;
  left: 50%;
  bottom: 100%;
  margin-bottom: 1.3px;
}

.pendingVerification::after,
.notVerified::after,
.verifiedDenied ::after {
  content: "";
  position: absolute;
  height: 13px;
  transform: translateX(-50%);
  width: 1.5px;
  left: 50%;
  background: #9ca3af;
  margin-top: 1.3px;
  top: 100%;
}

.pendingVerification::before {
  background: #6366f1;
}

.notVerified::before {
  background: #9ca3af;
}

.notVerified {
  border: 1.5px solid #9ca3af;
  color: #9ca3af;
}
