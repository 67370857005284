.image-gallery.listingDetailsSwiper {
  border: 1.5px solid #cad0d9;
  border-radius: 16px;
  padding: 50px 24px;
  overflow: hidden;
}
.image-gallery.listingDetailsSwiper .image-gallery-image {
  height: auto;
  border-radius: 8px;
}

/*arrows*/

.listingDetailsSwiper .arrows {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(238, 242, 255, 0.5);
  position: absolute;
  transform: translateY(-50%);
  z-index: 6;
  filter: none;
  color: #6366f1;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  top: 50%;
}

.listingDetailsSwiper .arrows.next-button {
  left: 0;
}
.listingDetailsSwiper .arrows.prev-button {
  right: 0;
}
.listingDetailsSwiper .arrows.prev-button:hover,
.listingDetailsSwiper .arrows.next-button:hover {
  font-size: 28px;
  color: #4f46e5;
}
.listingDetailsSwiper .thumbArrows {
  background-color: #4f46e5;
  color: #fff;
  border-radius: 8px;
  width: 25px;
  height: 25px;
  font-size: 10px;
}
.listingDetailsSwiper .thumbArrows.next-button {
  right: -76px;
  left: auto;
  top: -20px;
}

.listingDetailsSwiper .thumbArrows.prev-button {
  right: -76px;
  bottom: -45px;
  top: auto;
}
.listingDetailsSwiper .thumbArrows.prev-button:hover,
.listingDetailsSwiper .thumbArrows.next-button:hover {
  font-size: 12px;
  color: #fff;
}
/*Thumbnails*/
.listingDetailsSwiper .image-gallery-thumbnails {
  display: flex;
  justify-content: flex-end;
}

.listingDetailsSwiper .image-gallery-thumbnail {
  border: 2px solid #cad0d9;
  border-radius: 8px;
  overflow: hidden;
  width: 80px;
  height: 80px;
}

.listingDetailsSwiper .image-gallery-thumbnail.active {
  border: 2px solid #6366f1;
}

.listingDetailsSwiper .image-gallery-thumbnail .image-gallery-thumbnail-inner {
  width: 100%;
  height: 100%;
}

.listingDetailsSwiper
  .image-gallery-thumbnail
  .image-gallery-thumbnail-inner
  .image-gallery-thumbnail-image {
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.listingDetailsSwiper .image-gallery-thumbnail {
  margin-bottom: 10px;
}

.listingDetailsSwiper .image-gallery-description {
  display: none;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vw - 80px);
}

@media screen and (max-width: 1200px) {
  .listingDetailsSwiper .thumbArrows.next-button {
    left: 0;
  }
  .listingDetailsSwiper .thumbArrows.prev-button {
    right: 0;
  }
  .listingDetailsSwiper .image-gallery-thumbnails {
    overflow: hidden;
    margin: 0 auto;
    justify-content: center;
  }
  .listingDetailsSwiper .image-gallery-thumbnails-container {
    min-width: 95%;
    max-width: 100%;
  }
  .image-gallery-thumbnails-wrapper.bottom {
    overflow: hidden;
    width: calc(100% - 60px);
    margin: 0 auto;
  }
  .image-gallery.listingDetailsSwiper {
    padding: 50px 15px 0px 15px;
  }
  .listingDetailsSwiper .image-gallery-thumbnails-wrapper.bottom {
    padding-top: 15px;
  }
  .listingDetailsSwiper .thumbArrows.arrows {
    bottom: -82px;
    top: unset;
  }
}
