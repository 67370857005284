html,
body {
  height: 100%;
  width: 100%;
}
body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.link,
.link:focus,
.link:visited,
.link:active {
  text-decoration: none;
  color: inherit;
}

ul li::marker {
  font-size: 0.7rem;
}

.verificationInput {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: none;
  margin: 20px 5px;
}

/*Signup Collapse*/
.boxOnCollapse:has(button[aria-expanded="false"]) {
  margin: 16px 0;
  transition: 0.3s margin;
}

.boxOnCollapse:has(button[aria-expanded="true"]) {
  margin: 8px 0;
  transition: 0.3s margin;
}

.signupCollapse:has(button[aria-expanded="false"]) ~ .isAriaExpanded {
  align-items: stretch;
  flex-direction: column;
  text-align: center;
}

.signupCollapse:has(button[aria-expanded="true"]) ~ .isAriaExpanded {
  align-items: center;
  flex-direction: row;
  padding-top: 0;
  text-align: left;
}

@media screen and (min-width: 600px) {
  .boxOnCollapse:has(button[aria-expanded="false"]) {
    margin: 40px 0;
    transition: 0.3s margin;
  }
}

@media screen and (max-width: 1200px) {
  .signupCollapse:has(button[aria-expanded="true"]) ~ .isAriaExpanded {
    text-align: center;
  }
  
}

/*End Signup Collapse*/

.verificationProgress {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 1.5px;
  height: 60px;
  z-index: 0;
  margin-left: 10px;
}

.verificationProgress::before {
  position: absolute;
  content: "✓";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #6366f1;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: white;
  z-index: 1;
}

.attornyInformation,
.verifiedComplete {
  color: #6366f1;
  font-weight: 700 !important;
}

.notVerified {
  color: #9ca3af;
  font-weight: 700 !important;
}

.verificationProgress.notVerified {
  color: transparent;

  background: #9ca3af;
}

.verificationProgress.notVerified::before {
  color: transparent;
  border-color: #9ca3af;
}

.verificationProgress.attornyInformation {
  background: linear-gradient(
    0deg,
    rgba(156, 163, 175, 1) 50%,
    rgba(99, 102, 241, 1) 50%
  );
}

.verificationProgress.attornyInformation::before {
  color: #6366f1;
  border-color: #6366f1;
}

.verificationProgress.verifiedComplete {
  background: transparent;
}
.verificationProgress.verifiedComplete::before {
  color: #6366f1;
  border-color: #6366f1;
}

/*signupBorderFields border*/
.signupBorderFields {
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(229, 1, 235, 0) 7%,
      rgba(229, 231, 235, 1) 7%,
      rgba(229, 231, 235, 1) 94%,
      rgba(229, 231, 235, 1) 9%,
      rgba(229, 1, 235, 0) 9%
    )
    0% 0% 0% 1;
  position: relative;
}

/*OR border Line*/
.orBorderLine {
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(229, 231, 235, 0) 13%,
      rgba(229, 231, 235, 1) 13%,
      rgba(229, 231, 235, 1) 42%,
      rgba(98, 102, 241, 0) 42%,
      rgba(99, 102, 241, 0) 58%,
      rgba(229, 231, 235, 1) 58%,
      rgba(229, 231, 235, 1) 88%,
      rgba(229, 231, 235, 0) 88%
    )
    0% 0% 0% 1;
  position: relative;
}

.orBorderLine::after {
  content: "Or";
  font-family: poppins;
  font-size: 12px;
  color: #9ca3af;
  position: absolute;
  left: 0%;
  top: 50%;
  font-weight: 300;
  transform: translate(-50%, -50%);
}

/*End OR border Line*/

.mySwiper.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper .swiper-slide img {
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  border-radius: 8px;
  width: 15px;
  height: 15px;
  font-weight: 800;
  padding: 30px;
  border: 1.5px solid white;
  color: white;
}
.mySwiper .swiper-button-next {
  right: 150px;
}
.mySwiper .swiper-button-prev {
  left: 150px;
}

@media screen and (max-width: 1200px) {
  .mySwiper .swiper-slide img {
    width: 95%;
  }
  .mySwiper .swiper-button-next {
    right: 2.5%;
  }
  .mySwiper .swiper-button-prev {
    left: 2.5%;
  }
  .signupBorderFields {
    border-left: 0px solid;
  }
}
@media screen and (min-width: 900px) {
  body:has(.listingDetails) {
    background: linear-gradient(90deg, transparent 53.7vw, #f9fafb 46.3vw);
  }
}

/*Overflow scrollbar*/
::-webkit-scrollbar {
  background-color: #e5e7eb;
  width: 4px;
  height: 6px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #cad0d9;
  border-radius: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border-radius: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #686f7a;
  font-size: 0.75rem;
}
